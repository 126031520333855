import React, { useState } from "react";

import MainNavbar from "../MainNavbar"
import smoothScroll from "../../util/smoothScroll"

import { Section, 
         Container, 
         BrandLogo, 
         WrapIcons,
         Title,
         ButtonOpen,
         ButtonClose,
         ButtonMenuOpenClose,
         IconButtonPhone,
         IconButtonMarker,
         IconButtonWhatsapp } from "./styles"

const Header = ({ title }) => {

   const [isopen, setIsopen] = useState(false);
   const [btnOpen, setBtnOpen] = useState("")
   const [btnClose, setBtnClose] = useState("-disabled")
   const [isActive, setIsActive] = useState(false);
   const [path, setPath] = useState("/");

   const handleOnload = () => {
      if (typeof window !== `undefined`) {
         const $viewDoc = window.innerWidth;
         const $alturaDoc = document.documentElement.scrollHeight;
         const $Contacts = document.querySelector("#contatos");
         const $alturaContato = $Contacts.getBoundingClientRect().height;
         let $PosContact

         if ($viewDoc < 960) {
            $PosContact = $alturaDoc - ( $alturaContato + 90)
         } else {
            $PosContact = $alturaDoc - ( $alturaContato + 40)
         }
         
         return { $PosContact, $alturaDoc };
      }
   }
  
   const handleClickButtonMenu = () => {
      if (isopen) {
         setIsopen(false);
         setBtnOpen("")
         setBtnClose("-disabled")
         setIsActive(false);
      } else {
         setIsopen(true);
         setBtnOpen("-disabled")
         setBtnClose("")
         setIsActive(true);
      }
   }

   const handleClickMenu = event => {
      event.preventDefault();
      const $idTarget = event.target.id
      if ( $idTarget === "item-news") {
         setPath("/#novidades")
         handleClickButtonMenu()
      } else if ( $idTarget === "item-contact") {
         handleClickButtonMenu()
      }
   }

   const handleClickPhone = event => {
      event.preventDefault();
      const { $PosContact } = handleOnload()
      smoothScroll(window, 2000, $PosContact)
   }

   const handleClickMarker = event => {
      event.preventDefault();
      const { $alturaDoc } = handleOnload()
      smoothScroll(window, 2000, $alturaDoc)
   }
   
   return (
      <Section 
         onLoad={handleOnload} 
         onClick={handleClickMenu}>
         <Container>
            <Title>{title}</Title>
            <ButtonMenuOpenClose 
               isOpen={isopen} 
               onClick={handleClickButtonMenu}>
               <ButtonOpen className={btnOpen}>Menu</ButtonOpen>
               <ButtonClose className={btnClose}>Fechar</ButtonClose>
            </ButtonMenuOpenClose>
            <BrandLogo />
            <WrapIcons>
               <IconButtonPhone onClick={e => handleClickPhone(e)}>
                     (11)2574-9762
               </IconButtonPhone>
               <IconButtonMarker onClick={e => handleClickMarker(e)}>
                  Localização no mapa
               </IconButtonMarker>
               <IconButtonWhatsapp onClick="5511982971347" />
            </WrapIcons>
         </Container>
         <MainNavbar isActive={isActive} path={path}/>
      </Section>
   )
}

export default Header;