import React from "react";
import styled from "styled-components";
import media from "../styles/breakPoints"
import { FiPhone } from "react-icons/fi";

const LinkPhone = styled.a`
   width: 100%;
   height: 100%;
   cursor: pointer;

   ${media.greaterThan("lg")`
      transition: transform 300ms linear;
      &:hover {
         transform: scale(1.1);
         transition: transform 300ms linear;
      }
   `}

`

const LabelPhone = styled.span`
   display: none;
   text-indent: -9999px;
`

const IconPhone = styled(FiPhone)`
   display: block;
   width: 100%;
   height: 100%;
   color: ${props => props.theme.color.primaryLight1};
`

const ButtonPhone = ({ onClick, className, children }) => {

   return (
      <LinkPhone 
         href=""
         onClick={onClick} 
         className={className}>
            <LabelPhone>{children}</LabelPhone>
            <IconPhone />
      </LinkPhone>
   )

}

export default ButtonPhone;