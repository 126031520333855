import React from "react";
import styled from "styled-components"
import { Link } from "gatsby";

import imgLogoDark from "../images/globals/logo-dark.svg"
import imgLogoLight from "../images/globals/logo-light.svg"


const LinkLogo = styled(Link)`
   width: 216px;
   height: 64px;
   display: flex;
   align-items: center;
`

const LabelLogo = styled.span`
   display: none;
   text-indent: -9999px;
`

const Img = styled.img`
   width: 100%;
   height: 100%;
   margin: 0;
`

const Logo = ({ className, light=false }) => {

   return (
      <LinkLogo to="/" className={className}>
         <LabelLogo>Rosa Designer</LabelLogo>
         <Img src={light ? imgLogoLight : imgLogoDark} alt="logo da Rosa Designer"/>
      </LinkLogo>
   )
}

export default Logo