import React from "react";
import styled from "styled-components"
import media from "../styles/breakPoints"

const WrapSection = styled.section`
   width: 100%;

   ${media.greaterThan("lg")`
      border-left: 22.4rem solid transparent;
   `}
`

const MainSection = ({ children, className }) => {

   return (
      <WrapSection className={className}>
         {children}
      </WrapSection>
   )
}

export default MainSection