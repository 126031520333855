import React from "react";
import styled from "styled-components";

import icoHamburg from "../images/icon-hamburg.svg"

const LinkMenuHamburg = styled.button`
   width: 2.4rem;
   height: 2.4rem;
   display: flex;
   justify-content: center;
   align-items: center;
   cursor: pointer;
   outline: none;
   border: none;
   padding: 0;
   background-color: transparent;
`

export const LabelHamburg = styled.span`
   display: inline-block;
`

const Icon = styled.img`
   width: 100%;
   height: 100%;
   margin: 0;
`

const ButtonHamburg = ({ className, children }) => {

   return (
      <LinkMenuHamburg id="btnopen" className={className}>
         <LabelHamburg>{children}</LabelHamburg>
         <Icon src={icoHamburg} alt="botão para abrir menu mobile"/>
      </LinkMenuHamburg>
   )

}

export default ButtonHamburg;