import styled from "styled-components";
import { Link } from "gatsby";
import media from "../../styles/breakPoints"

const WrapNav = styled.nav`
   position: absolute;
   width: 100%;
   height: 100vh;
   top: 0;
   left: 0;
   display: block;
   background-color: ${props => props.theme.color.primaryBase};
   transform: translateX(-100%);
   transition: transform 400ms linear;
   z-index: 10;
   &.-isActive {
      transform: translateX(0);
      transition: transform 400ms linear;
   }

   ${media.greaterThan("md")`
      z-index: -2;
      &.-isActive {
         width: 22.4rem;
         transform: translateX(100%);
         background-color: rgba(57,47,45,0.95);
      }
   `}

   ${media.greaterThan("lg")`
      top: 4rem;
   `}

`

const ListItem = styled.ul`
   position: absolute;
   top: 0;
   left: 0;
   display: block;
   width: 100%;
   height: auto;
   text-decoration: none;
   line-height: 3.8rem;
   list-style: none;
   transition: transform 300ms linear;
   margin: 0;


   ${media.greaterThan("md")`
      top: 5rem;
   `}

   ${media.greaterThan("lg")`
      top: 11.5rem;
   `}
`

const Item = styled.li`
   display: block;
   margin: 0;
   z-index: 20;
`

const Action = styled(Link)`
   display: block;
   width: 100%;
   height: 100%;
   font-family: ${props => props.theme.font.secondy};
   font-size: ${props => props.theme.size.small};
   font-weight: 400;
   color: ${props => props.theme.color.thirdLight2};
   text-decoration: none;
   padding-left: 2rem;
   cursor: pointer;
   &:hover {
      background-color: ${props => props.theme.color.primaryDark2};
   }
`

export {
   WrapNav,
   ListItem,
   Item,
   Action
}