const theme = {
  color: {
    primaryLight2: "#675551", // (primary-color-1) marrom mais claro
    primaryLight1: "#5b4c48", // (primary-color-2) marrom claro
    primaryBase: "#483C39", // (primary-color) marron médio base
    primaryDark1: "#453936", // (primary-color-3) marrom escuro
    primaryDark2: "#392f2d", // (primary-color-4) marrom mais escuro

    secondyLight2: "#FFF6EB", // (secondy-color-1) pastel mais claro
    secondyLight1: "#FFEDD6", // (secondy-color-2) pastel claro
    secondyBase: "#FFE4C1", // (secondy-color) pastel médio base
    secondyDark1: "#FFDCAD", // (secondy-color-4) pastel escuro
    secondyDark2: "#FFD399", // (secondy-color-5) pastel mais escuro

    thirdLight2: "#F9C8CA", // (base-coral-1) coral mais claro
    thirdLight1: "#F4A4A6", // (base-coral-2) coral claro
    thirdBase: "#EF767A", // (base-coral) coral médio base
    thirdDark1: "#EC5B60", // (base-coral-3) coral escuro
    thirdDark2: "#EA484E", // (base-coral-4) coral mais escuro

    fourthLight2: "#96D9CE", // (base-green-1) green mais claro
    fourthLight1: "#69C9B9", // (base-green-2) green claro
    fourthBase: "#49BEAA", // (base-green) green médio base
    fourthDark1: "#3BA593", // (base-green-3) green escuro
    fourthDark2: "#318779", // (base-green-4) green mais escuro

    fifthLight2: "#CCF5E9", // (base-greenlight-1) green mais claro
    fifthLight1: "#76E5C4", // (base-greenlight-2) green claro
    fifthBase: "#49DCB1", // (base-greenlight) green claro base
    fifthDark1: "#27CE9C", // (base-greenlight-3) green escuro
    fifthDark2: "#24BC8F", // (base-greenlight-4) green mais escuro

    grayDark: "#121A19", // (support-black) cinza bem escuro
    grayMediumDark: "#243331", // (support-black-4) cinza escuro
    grayMedium: "#526663", // (support-black-3) cinza médio
    grayLight: "#8A9997", // (support-black-2) cinza claro
    grayClean: "#E9F0EF", // (support-black-1) cinza bem claro
    grayZero: "#FFFFFF", // branco

  },
  shadow: {
    primary: `0px 1px 5px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.14)`
  },
  font: {
    primary: `
     Montserrat,
     Roboto,
     Helvetica,
     Arial,
     sans-serif,
     -apple-system,
     BlinkMacSystemFont`,
    secondy: `
     "Open Sans",
     Roboto,
     Helvetica,
     Arial,
     sans-serif,
     -apple-system,
     BlinkMacSystemFont`
  },
  size: {
    verySmall: "1.4rem", // caption
    small: "1.6rem", // p
    mediumSmall: "1.8rem", // h3
    medium: "2.4rem", // h3
    big: "3.2rem", // h2
    veryBig: "4rem" // h1
  }
};

export default theme;
