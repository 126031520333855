import styled from "styled-components";
import { Link } from "gatsby";

import media from "../../styles/breakPoints"
import Brand from "../../objects/Brand"
import IconArrowRightZero from "../../images/icon-chevron-right-zero.svg"
import IconArrowRightFull from "../../images/icon-chevron-right-full.svg"

const BrandLogo = styled(Brand)`
   width: 18rem;
`

const WrapBrand = styled.div`
   top: 0;
   width: 100%;
   display: none;
   justify-content: center;
   align-items: flex-start;
   background-color: ${props => props.theme.color.primaryBase};
`

const MainNav = styled.nav`
   position: fixed;
   display: block;
   width: 100vw;
   top: 9rem;
   left: 0;
   background-color: ${props => props.theme.color.primaryBase};
   z-index: 10;
   padding: 0;
   transform: translateX(-100%);
   transition: transform 400ms ease-out;
   &.-isActive {
      transform: translateX(0);
      transition: transform 400ms ease-out;
   }

   ${media.greaterThan("md")`
      width: 22.4rem;
   `}

   ${media.greaterThan("lg")`
      top: 0;
      transform: translateX(0);
      display: flex;
      flex-direction: column;
      align-items: center;
      & ${WrapBrand} {
         padding-top: 3rem;
         display: flex;
      }
   `}

`

const ListItem = styled.ul`
   display: block;
   width: 100%;
   height: 100vh;
   text-decoration: none;
   list-style: none;
   transition: transform 400ms linear;
   padding: 0;
   margin: 0;
   background-color: ${props => props.theme.color.primaryBase};

   ${media.greaterThan("md")`
      padding-top: 5rem;
   `}

   ${media.greaterThan("lg")`
      padding-top: 6rem;
   `}

`

const Item = styled.li`
   display: block;
   margin: 0;
   transition: all 400ms linear;
   z-index: 20;
`

const Action = styled(Link)`
   display: block;
   width: 100%;
   height: 100%;
   font-family: ${props => props.theme.font.secondy};
   font-size: ${props => props.theme.size.small};
   font-weight: 400;
   line-height: 4rem;
   color: ${props => props.theme.color.secondyLight1};
   background-color: ${props => props.theme.color.primaryBase};
   text-decoration: none;
   padding: 0 1.5rem;
   cursor: pointer;
   transition: all 400ms linear;
   &.active {
      background-color: ${props => props.theme.color.primaryDark2};
   }
   &:hover {
      background-color: ${props => props.theme.color.primaryDark2};
      transition: all 400ms linear;
   }

   ${media.greaterThan("lg")`
      padding: 0 1.5rem 0 6rem;
   `}

`

// ITENS PARA SUBMENU
const ActionSpan = styled.span`
   width: 100%;
   height: 100%;
   display: inline-flex;
   justify-content: space-between;
   align-items: center;
   font-family: ${props => props.theme.font.secondy};
   font-size: ${props => props.theme.size.small};
   font-weight: 400;
   line-height: 4rem;
   color: ${props => props.theme.color.secondyLight1};
   text-decoration: none;
   padding: 0 1.5rem;
   transition: all 400ms linear;
   cursor: pointer;

   background-image: url(${IconArrowRightZero});
   background-repeat: no-repeat;
   background-size: 30% 30%;
   background-position: center right;
   &.active {
      background-color: ${props => props.theme.color.primaryDark2};
      background-image: url(${IconArrowRightFull});
      background-position: center right -20px;
   }
   &:hover {
      background-color: ${props => props.theme.color.primaryDark2};
      transition: all 400ms linear;
      background-position: center right -20px;
      background-image: url(${IconArrowRightFull});
   }
   &.-simple {
      background-image: none;
      &:hover {
         background-color: ${props => props.theme.color.primaryDark2};
         transition: all 400ms linear;
      }
   }

   ${media.greaterThan("lg")`
      padding: 0 1.5rem 0 6rem;
   `}

`

export {
   MainNav,
   BrandLogo,
   WrapBrand,
   ListItem,
   Item,
   Action,
   ActionSpan,
}