import React, { useState, useEffect } from "react";

import { SubNavFurniture, SubNavDecoration } from "../SubNavbar"
import smoothScroll from "../../util/smoothScroll"

import { MainNav, 
         BrandLogo,
         WrapBrand,
         ListItem, 
         Item, 
         Action,
         ActionSpan } from "./styles"

const MainNavbar = ({   isActive=false,
                        isShow=false, 
                        className,
                        path="/" }) => {

   const [subActiveFurniture, setSubActiveFurniture] = useState(false)
   const [subActiveDecoration, setSubActiveDecoration] = useState(false)

   const handleClickFurniture = () => {
      if (subActiveDecoration) { setSubActiveDecoration(false) }
      subActiveFurniture ? 
         setSubActiveFurniture(false) :
         setSubActiveFurniture(true)
   }

   const handleClickDecoration = () => {
      if (subActiveFurniture) { setSubActiveFurniture(false) }
      subActiveDecoration ? 
         setSubActiveDecoration(false) :
         setSubActiveDecoration(true)
   }

   const handleLeave = () => {
      setSubActiveFurniture(false)
      setSubActiveDecoration(false)
   }

   const handleOnload = () => {
      if (typeof window !== `undefined`) {
         const $viewDoc = window.innerWidth;
         const $heightDoc = window.innerHeight;
         const $alturaDoc = document.documentElement.scrollHeight;
         const $Contacts = document.querySelector("#contatos");
         const $MainNav = document.querySelector("#mainnav");

         const $Furniture = document.querySelector("#furniture");
         $Furniture.addEventListener
            ("mouseleave", handleLeave);
         const $Decoration = document.querySelector("#decoration");
         $Decoration.addEventListener
            ("mouseleave", handleLeave);

         const $alturaContato = $Contacts.getBoundingClientRect().height;
         let $PosContact
         let $PosNews
         if ($viewDoc < 960) {
            $PosNews = $heightDoc - 90
            $PosContact = $alturaDoc - ( $alturaContato + 90)
         } else {
            $PosNews = $heightDoc - 40
            $PosContact = $alturaDoc - ( $alturaContato + 40)
         }
         
         return { $PosNews, $PosContact, $MainNav };
      }
   }

   const handleClickItem = (e, target) => {
      e.preventDefault();
      const { $PosNews, $PosContact, $MainNav } = handleOnload()
      $MainNav.classList.remove("-isActive");

      if (target === "#contatos") {
         smoothScroll(window, 2000, $PosContact)
      } else if (target === "#novidades") {
         smoothScroll(window, 2000, $PosNews)
      }

   }

   useEffect(() => {
      setSubActiveFurniture(false)
      setSubActiveDecoration(false)
   }, [isActive])

   return (
      <MainNav 
         id="mainnav" 
         onLoad={handleOnload} 
         className={`${isActive ? "-isActive" : "" }`} >
         <WrapBrand>
            <BrandLogo light={true}/>
         </WrapBrand>
         <ListItem>
            <Item onMouseOver={handleLeave} >
               <Action activeClassName="active" to="/">
                  Home
               </Action>
            </Item>
            <Item onMouseOver={handleLeave}>
               <Action activeClassName="active" to="/QuemSomos">
                  Quem Somos
               </Action>
            </Item>
            <Item
               onMouseOver={handleLeave}
               onClick={e => handleClickItem(e, "#novidades")}>
               <Action id="item-news" to={path}>
                  Novidades
               </Action>
            </Item>
            <Item 
               onPress={handleClickFurniture}
               onMouseOver= {handleClickFurniture}>
               <ActionSpan 
                  className={`${subActiveFurniture ? "active" : "" }`}>
                     Móveis
               </ActionSpan>
            </Item>
            <Item 
               onMouseOver={handleClickDecoration}
               onPress={handleClickDecoration}>
               <ActionSpan 
                  className={`${subActiveDecoration ? "active" : "" }`}>
                     Decoração
               </ActionSpan>
            </Item>
            <Item onMouseOver={handleLeave}>
               <Action activeClassName="active" to="/Corporativo">
                  Corporativo
               </Action>
            </Item>
            <Item
               onClick={e => handleClickItem(e, "#contatos")}
               onMouseOver={handleLeave}>
               <ActionSpan id="item-contact" className="-simple">
                  Contatos
               </ActionSpan>
            </Item>
         </ListItem>
         <SubNavFurniture subActive={subActiveFurniture} />
         <SubNavDecoration subActive={subActiveDecoration} />
      </MainNav>
   )
}

export default MainNavbar;