import styled from "styled-components"
import media from "../../styles/breakPoints"

const WrapMainFooter = styled.footer`
   width: 100%;

   ${media.greaterThan("lg")`
      border-left: 22.4rem solid transparent;
   `}

`
// Part 1
const WrapCallTo = styled.div`
   width: 100%;
   display: block;
   background-color: ${props => props.theme.color.fourthDark1};
   text-align: center;
   padding: 40px;

   ${media.greaterThan("md")`
      padding: 50px 80px;
   `}

`

const CallTitle = styled.h2`
   width: 100%;
   font-family: ${props => props.theme.font.primary};
   font-weight: 600;
   font-size: ${props => props.theme.size.medium};
   line-height: 3rem;
   color: ${props => props.theme.color.secondyLight2};
`

const CallDestak = styled(CallTitle)`
   text-transform: uppercase;
   margin-bottom: 0;
`

const CallContacts = styled.p`
   width: 100%;
   font-family: ${props => props.theme.font.secondy};
   font-weight: 400;
   font-size: ${props => props.theme.size.small};
   color: ${props => props.theme.color.grayZero};
   margin: 0;
   padding-top: 2rem;

   ${media.greaterThan("md")`
      font-size: ${props => props.theme.size.medium};
   `}

`

// Part 2
const WrapFooter = styled.div`
   width: 100%;
   display: inline-flex;
   flex-wrap: wrap;
   justify-content: space-between;
   align-items: flex-start;
   background-color: ${props => props.theme.color.primaryBase};
   text-align: center;
   padding: 35px 16px;
`

const FooterBrand = styled.div`
   width: 100%;
   height: 48px;   
   margin-bottom: 3rem;

   ${media.greaterThan("md")`
      height: 76px;
      margin-bottom: 6rem;
   `}

`

const FooterTitle = styled.h3`
   width: 100%;
   font-family: ${props => props.theme.font.primary};
   font-weight: 600;
   font-size: ${props => props.theme.size.verySmall};
   color: ${props => props.theme.color.secondyDark1};
   margin: 0;
   padding-bottom: 10px;

   ${media.greaterThan("md")`
      font-size: ${props => props.theme.size.medium};
   `}

`

const FooterContent = styled.p`
   font-family: ${props => props.theme.font.secondy};
   font-weight: 400;
   font-size: ${props => props.theme.size.verySmall};
   color: ${props => props.theme.color.secondyLight2};
   margin: 0;

   ${media.greaterThan("md")`
      font-size: ${props => props.theme.size.small};
   `}

`

const FooterShowroom = styled.div`
   width: 35%;
   text-align: left;
   margin-bottom: 30px;
   & ${FooterTitle} {
      padding-bottom: 5px;
   }
`

const FooterExpediente = styled.div`
   width: 35%;
   text-align: left;
   & ${FooterTitle} {
      padding-bottom: 5px;
   }
`

const FooterRedesSocials = styled.div`
   width: 25%;
   text-align: left;
   & ${FooterContent} {
      height: 24px;
      display: flex;
      > * {
         width: 24px;
         height: 100%;
         margin: 0 1.6rem 0 0;
      }
   }

   ${media.greaterThan("md")`
      & ${FooterContent} {
         height: 32px;
         > * {
            width: 32px;
            margin: 0 2rem 0 0;
         }
      }
   `}

   ${media.greaterThan("xl")`
      width: 19%;
   `}

`

const FooterCopyright = styled.div`
   width: 100%;
   text-align: center;
   padding: 50px 0 0;
`

export {
   WrapMainFooter,
   WrapCallTo,
   CallTitle,
   CallDestak,
   CallContacts,
   WrapFooter,
   FooterBrand,
   FooterShowroom,
   FooterExpediente,
   FooterRedesSocials,
   FooterCopyright,
   FooterTitle,
   FooterContent
}