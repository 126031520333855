import React from "react";
import styled from "styled-components";
import media from "../styles/breakPoints"
import { FiMapPin } from "react-icons/fi";

const LinkMapPin = styled.a`
   width: 100%;
   height: 100%;
   cursor: pointer;

   ${media.greaterThan("lg")`
      transition: transform 300ms linear;
      &:hover {
         transform: scale(1.1);
         transition: transform 300ms linear;
      }
   `}

`

const LabelMarker = styled.span`
   display: none;
   text-indent: -9999px;
`

const IconMarker = styled(FiMapPin)`
   display: block;
   width: 100%;
   height: 100%;
   color: ${props => props.theme.color.primaryLight1};
`

const ButtonMarker = ({ onClick, className, children }) => {

   return (
      <LinkMapPin 
         href="/" 
         onClick={onClick} 
         className={className}>
            <LabelMarker>{children}</LabelMarker>
            <IconMarker />
      </LinkMapPin>
   )

}

export default ButtonMarker;