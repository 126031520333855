import React from "react";

import { WrapNav, ListItem, Item, Action } from "./styles"

export const SubNavFurniture = ({ subActive }) => (
   <WrapNav 
      id="furniture" 
      className={`${subActive ? "-isActive" : "" }`}>
      <ListItem>
         <Item><Action to="/Acabamentos">Acabamentos</Action></Item>
         <Item><Action to="/Balancos">Balanços</Action></Item>
         <Item><Action to="/Bancos">Bancos</Action></Item>
         <Item><Action to="/Banquetas">Banquetas</Action></Item>
         <Item><Action to="/Chaises">Chaise & Daybeds</Action></Item>
         <Item>
            <Action to="/Espreguicadeiras">Espreguiçadeiras</Action>
         </Item>
         <Item><Action to="/Mesas">Mesas</Action></Item>
         <Item><Action to="/Ombrelones">Ombrelones</Action></Item>
         <Item><Action to="/Puffs">Puffs</Action></Item>
         <Item><Action to="/SofasPoltronas">Sofás e Poltronas</Action></Item>
      </ListItem>
   </WrapNav>
)


export const SubNavDecoration = ({ subActive }) => (

   <WrapNav 
      id="decoration" 
      className={`${subActive ? "-isActive" : "" }`}>
      <ListItem>
         <Item>
            <Action to="/CortinasPersianas">Cortinas e Persianas</Action>
         </Item>
         <Item>
            <Action to="/VasosVietnamitas">Vasos Vietnamitas</Action>
         </Item>
      </ListItem>
   </WrapNav>
)