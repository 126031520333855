import React from "react";
import styled from "styled-components";
import media from "../styles/breakPoints"
import { FaWhatsapp } from "react-icons/fa";


const LinkWhatsapp = styled.a`
   width: 100%;
   height: 100%;
   cursor: pointer;

   ${media.greaterThan("lg")`
      transition: transform 300ms linear;
      &:hover {
         transform: scale(1.1);
         transition: transform 300ms linear;
      }
   `}

`

const LabelWhatsapp = styled.span`
   display: none;
   text-indent: -9999px;
`

const IconWhatsapp = styled(FaWhatsapp)`
   display: block;
   width: 100%;
   height: 100%;
   color: ${props => props.theme.color.primaryLight1};
`

const ButtonWhatsapp = ({  onClick, 
                           className }) => {

   return (
      <LinkWhatsapp 
         href={`http://api.whatsapp.com/send?1=pt_BR&phone=${onClick}`}
         rel="noopener"
         target="_blank"
         onClick={onClick} 
         className={className}>
         <LabelWhatsapp>Link para Whatsapp</LabelWhatsapp>
         <IconWhatsapp />
      </LinkWhatsapp>
   )

}

export default ButtonWhatsapp;