import React from "react";
import styled from "styled-components";
import { AiOutlineCloseCircle } from "react-icons/ai";

const LinkClose = styled.button`
   display: flex;
   justify-content: flex-start;
   align-items: flex-start;
   cursor: pointer;
   outline: none;
   border: none;
   padding: 0;
   background-color: transparent;
`

export const LabelClose = styled.span`
   display: inline-block;
`

const IconClose = styled(AiOutlineCloseCircle)`
   width: 2.6rem;
   height: 2.6rem;
   color: ${props => props.theme.color.primaryLight1};
`

const ButtonClose = ({ onClick, className, children }) => {

   return (
      <LinkClose 
         id="btnclose" 
         onClick={onClick} 
         className={className}>
            <LabelClose>{children}</LabelClose>
            <IconClose />
      </LinkClose>
   )

}

export default ButtonClose;