import styled from "styled-components";
import media from "../../styles/breakPoints"

import ButtonHamburg, { LabelHamburg } from "../../objects/ButtonHamburg"
import ButtonX, { LabelClose } from "../../objects/ButtonX"
import Brand from "../../objects/Brand"
import ButtonPhone from "../../objects/ButtonPhone"
import ButtonMarker from "../../objects/ButtonMarker"
import ButtonWhatsapp from "../../objects/ButtonWhatsapp"

const Section = styled.header`
   position: fixed;
   top: 0;
   width: 100%;
   height: 9rem;
   display: flex;
   justify-content: center;
   align-items: center;
   z-index: 12;
   background-color: ${props => props.theme.color.secondyLight2};

   ${media.greaterThan("lg")`
      height: 4rem;
      background-color: ${props => props.theme.color.fourthLight2};
      z-index: 10;
   `}

`

const Container = styled.section`
   width: 100%;
   height: 100%;
   margin: 0 auto;
   display: flex;
   justify-content: space-between;
   align-items: flex-start;
   padding: 22px 16px 16px;

   ${media.greaterThan("md")`
      align-items: center;
      padding: 0 16px;
   `}

   ${media.greaterThan("lg")`
      justify-content: flex-end;
      align-items: center;
      padding: 0 65px;
      border-left: 22.4rem solid transparent;
   `}

`

const ButtonOpen = styled(ButtonHamburg)`
   display: block;
   width: 100%;
   height: 100%;
   & ${LabelHamburg} {
      display: none;
      text-indent: -9999px;
   }
   &.-disabled {
      display: none;
   }
`

const ButtonClose = styled(ButtonX)`
   display: block;
   width: 100%;
   height: 100%;
   & ${LabelClose} {
      display: none;
      text-indent: -9999px;
   }
   &.-disabled {
      display: none;
   }
`

const ButtonMenuOpenClose = styled.button`
   width: 24px;
   height: 24px;
   padding: 0;
   border: none;
   background-color: transparent;
   outline: none;
   cursor: pointer;
   
   ${media.greaterThan("md")`
      margin-right: 80px;
   `}

   ${media.greaterThan("lg")`
      display: none;
   `}

`

const BrandLogo = styled(Brand)`
   transform: translateY(-8px);
   
   ${media.greaterThan("md")`
      transform: translateX(10px);
   `}
   
   ${media.greaterThan("lg")`
      display: none;
   `}

`

const Title = styled.div`
   display: none;
   font-family: ${props => props.theme.font.secondy};
   font-weight: 400;
   font-size: ${props => props.theme.size.verySmall};
   text-align: center;
   color: ${props => props.theme.color.primaryLight1};
   flex: 1;
   padding-left: 80px;

   ${media.greaterThan("lg")`
      display: flex;
      justify-content: center;
   `}
`

const WrapIcons = styled.div`
   width: 2.4rem;
   height: 2.4rem;
   display: flex;
   justify-content: flex-start;
   align-items: center;

   ${media.greaterThan("md")`
      justify-content: space-between;
      width: 120px;
   `}
   
   ${media.greaterThan("lg")`
      justify-content: space-between;
      width: 90px;
      align-items: center;
   `}

`

const IconButtonPhone = styled(ButtonPhone)`
   display: none;

   ${media.greaterThan("md")`
      display: flex;
      width: 2.6rem;
      height: 2.6rem;
   `}

   ${media.greaterThan("lg")`
      width: 2rem;
      height: 2rem;
   `}

`

const IconButtonMarker = styled(ButtonMarker)`
   display: none;

   ${media.greaterThan("md")`
      display: flex;
      width: 2.6rem;
      height: 2.6rem;
   `}
 
   ${media.greaterThan("lg")`
      width: 2rem;
      height: 2rem;
   `}

`

const IconButtonWhatsapp = styled(ButtonWhatsapp)`
   width: 2.6rem;
   height: 2.6rem;
   
   ${media.greaterThan("lg")`
      width: 2.2rem;
      height: 2.2rem;
   `}

`

export {
   Section,
   Container,
   BrandLogo,
   Title,
   WrapIcons,
   ButtonOpen,
   ButtonClose,
   ButtonMenuOpenClose,
   IconButtonPhone,
   IconButtonMarker,
   IconButtonWhatsapp
}