import React from "react"

import BrandIcon from "../../objects/BrandIcon"
import IconInstagram from "../../objects/ButtonInstagram"
import IconFacebook from "../../objects/ButtonFacebook"

import { WrapMainFooter,
         WrapCallTo,
         CallTitle,
         CallDestak,
         CallContacts,
         WrapFooter,
         FooterBrand,
         FooterShowroom,
         FooterExpediente,
         FooterRedesSocials,
         FooterCopyright,
         FooterTitle,
         FooterContent } from "./styles"

const Footer = () => {
   return(
      <WrapMainFooter id="contatos">
         <WrapCallTo>
            <CallTitle>
               <CallDestak>Ligue para a Rosa</CallDestak>
               e se surpreenda com o atendimento
            </CallTitle>
            <CallContacts>
               Whatsapp:  11 98297.1347
            </CallContacts>
            <CallContacts>
               rosa@rosadesigner.com.br
            </CallContacts>
         </WrapCallTo>
         <WrapFooter>
            <FooterBrand>
               <BrandIcon />
            </FooterBrand>
            <FooterShowroom>
               <FooterTitle className="title">Showroom</FooterTitle>
               <FooterContent className="description">
                  Araçoiaba da Serra - SP
               </FooterContent>
            </FooterShowroom>
            <FooterExpediente>
               <FooterTitle className="title">Atendimento</FooterTitle>
               <FooterContent className="description">
                  Segunda - Sábado
               </FooterContent>
               <FooterContent className="description">
                  9h às 18h
               </FooterContent>
            </FooterExpediente>
            <FooterRedesSocials>
               <FooterTitle className="title">
                  Redes sociais
               </FooterTitle>
               <FooterContent className="description">
                  <IconInstagram 
                     target="https://www.instagram.com/rosadesigner.moveis/?hl=pt" />
                  <IconFacebook 
                     target="https://www.facebook.com/rosadesignermoveis" />
               </FooterContent>
            </FooterRedesSocials>
            <FooterCopyright>
               <FooterContent>
                  Rosa Designer ©2021 todos os direitos reservados.  
               </FooterContent>
            </FooterCopyright>
         </WrapFooter>
      </WrapMainFooter>
   )
}

export default Footer